/* =======================================================================
Forms
========================================================================== */

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), textarea {
	border: 1px solid #ccc;
	outline: none;
	transition: all 300ms ease;
	padding: 5px 10px;
	margin: 5px 0px;
	width: 100%;
	max-width: 600px;
	border-radius: 5px;

	&:focus {
		border: 1px solid $orange;
	}
}

.wpcf7-submit {
	border: 1px solid darken($blue-light, 10);
	background: $blue-light;
	padding: 10px 20px;
	display: block;
	width: auto;
	color: white;
	max-width: 600px;
	font-family: $montserrat;
	font-weight: bold;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	outline: none;
	border-radius: 5px;
	margin-top: 10px;

	&:hover {
		border: 1px solid darken($blue-light, 20);
			background: darken($blue-light, 5);
	}
}

div.wpcf7-validation-errors {
	background: #c0392b;
	color: white;
	max-width: 600px;
	padding: 15px 20px;
	border: none;
	margin: 0px;
	text-align: center;
}

div.wpcf7-mail-sent-ok {
	background: #27ae60;
	color: white;
	max-width: 600px;
	padding: 15px 20px;
	border: none;
	margin: 0px;
	text-align: center;
}