.c-clinique-carousel {
	margin: 40px 0px;
	position: relative;
}

.c-clinique-carousel__slide {
	padding: 20px;
}

.c-clinique-carousel__image {
	height: 240px !important;
	display: block;
	width: auto;
	background-size: cover;
	background-position: 50% 50%;

	@include mq('medium') {
		height: 200px;
	}
}

.c-clinique-carousel__prev,
.c-clinique-carousel__next {
	border: 1px solid darken($blue-light, 15);
	background: $blue-light;
	color: white;
	height: 40px;
	width: 40px;
	transform: scale(1);
	transition: all 300ms ease;
	position: absolute;
	top: calc(50% - 20px);
	z-index: 2;

	&:hover {
		transform: scale(1.1);
		background: darken($blue-light, 10);
		color: white;
	}

}

.c-clinique-carousel__prev {
	left: 0px;
}

.c-clinique-carousel__next {
	right: 0px;
}