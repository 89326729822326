/* =======================================================================
Layout
========================================================================== */

.l-container {
	clear: both;
	max-width: 1440px;
	margin: 0 auto;

	&.flex {
		display: flex;
	}

	h1 {
		color: $orange;
		font-size: 5rem;
		margin-bottom: 1em;

		@include mq('medium') {
			font-size: 3rem;
		}

		strong {
			display: inline-block;
		}
	}

	h2 {
		color: $blue-light;
		font-size: 1.6rem;
		font-family: $montserrat;
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 2em;
	}

	p, li {
		font-family: $roboto;
		color: #666;
		font-weight: 400;
		font-size: 1.4rem;
		line-height: 1.6em;
	}
}

.l-12 {
	width: 100%;
	padding: 40px 80px;

	@include mq(medium) {
		padding: 30px 20px;
	}
}

.l-6 {
	width: 50%;
	float: left;
	padding: 40px;

	@include mq(medium) {
		padding: 30px 20px;
	}
}