.c-header {
	height: 0px;
	position: relative;
	z-index: 4;
}

.c-header--with-bg {
	background: url('../images/header.jpg');
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	height: 300px;
	position: relative;

	@include mq('medium') {
		height: 200px;
	}

	.c-header__mobile-btn {
		color: white;

		&:before {
			background-image: url('../images/mobile-nav-white.png');
		}

		&:hover {
			color: white;
		}
	}
}

.c-header--contact {
	background-image: url('../images/header-highres.jpg');
	height: auto;
	padding-top: 175px;
	padding-bottom: 60px;

	@include mq('medium') {
		padding-bottom: 0px;
	}
}

.c-header__logo {
	position: absolute;
	top: 30px;
	right: 40px;

	img {
		width: 120px;

		@include mq('medium') {
			width: 90px;
		}
	}

	@include mq('medium') {
		right: 20px;
	}
}

.c-header__mobile-btn {
	position: absolute;
	top: 40px;
	left: 40px;
	color: black;
	font-family: $montserrat;
	font-size: 2rem;
	text-transform: uppercase;
	outline: none;
	transition: all 300ms ease-in-out;

	&:hover {
		color: #7bbee9;
		text-decoration: none;

		&:before {
			margin-right: 7px;
		}
	}

	&:before {
		content: "";
		background-image: url('../images/mobile-nav.png');
		width: 25px;
		height: 15px;
		background-size: contain;
		background-repeat: no-repeat;
		display: inline-block;
		margin-right: 5px;
		transition: all 300ms ease-in-out;
	}

	@include mq('medium') {
		left: 20px;
	}
}

.c-header__content {

	max-width: 700px;
	margin-left: auto;
	margin-right: 40px;
	border-radius: 5px;
	background: linear-gradient(rgba(#f18e17, 0.9),rgba(#f18e17, 0.9));
	padding: 60px 40px 20px 40px;
	box-shadow: 0px 0px 10px rgba(0,0,0,.1);

	@include mq('medium') {
		max-width: none;
		width: 100%;
		margin: 0px;
		border-radius: 0px;
	}

	h1 {
		margin-top: 0px;
		font-size: 3.5rem;
		color: white;

		@include mq('medium') {
			font-size: 3rem;
		}
	}
}