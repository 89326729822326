.c-mobile-menu {
	background: rgba(#7bbee9, 0.98);
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	padding-left: 140px;
	padding-top: 120px;
	transform: translateY(-100%) scale(2);
	opacity: 0;
    transition: all 300ms ease-in-out, transform 600ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
	pointer-events: none;
	overflow-y: auto;
	display: flex;
	align-items: center;

	@include mq(medium) {
		padding-left: 40px;
		padding-top: 100px;
	}

	&.active {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(0%) scale(1);

		&:before {
			transform: translateY(0px);
			opacity: 1;
			transition-delay: 700ms;
		}

		.c-mobile-menu__close {
			transform: translateY(0px);
			opacity: 1;
			transition-delay: 500ms;
		}
	}

	&:before {
		content: "";
		background-image: url('../images/logo-white.png');
		position: absolute;
		right: 140px;
		top: 40px;
		height: 90px;
		width: 90px;
		background-size: contain;
		background-repeat: no-repeat;
		transition: all 500ms ease-in-out;
		outline: none;
		opacity: 0;
		transform: translateY(-30px);
		transition-delay: 0ms;

		@include mq('medium') {
			right: 40px
		}
	}
}

.c-mobile-menu ul {
	padding-left: 0px;
	margin-top: -40px;
}

.c-mobile-menu li {
	list-style: none;

	&.phone {
		margin-top: 2em;
	}
}

.c-mobile-menu li.current-menu-item a {
	color: #4763a9;
}

.c-mobile-menu li a {
	color: #ffffff;
	text-decoration: none;
	font-size: 3.5vw;
	text-transform: uppercase;
	text-decoration: none;
	font-family: $montserrat;
	line-height: 1.3em;
	transition: all 250ms ease-in-out;

	@include mq('small') {
		font-size: 2.8rem;
	}

	@include mq('above-large') {
		font-size: 3vw;
	}

	&:hover {
		color: darken(#4763a9, 15);
	}

}

.c-mobile-menu__close {
	position: absolute;
	top: 40px;
	left: 140px;
	background: transparent;
	border: none;
	text-transform: uppercase;
	color: white;
	font-family: $montserrat;
	font-size: 2rem;
	transition: all 400ms ease-in-out;
	outline: none;
	opacity: 0;
	transform: translateY(-30px);
	transition-delay: 0ms;

	@include mq('medium') {
		left: 40px;
	}

	&:hover {
		opacity: 0.8;

		&:before {
			margin-right: 10px;
			transform: rotate(90deg);
		}
	}

	&:before {
		content: "";
		display: inline-block;
		background-image: url('../images/close.png');
		height: 15px;
		width: 15px;
		margin-right: 5px;
		background-size: contain;
		transition: all 300ms ease-in-out;
	}
}

.bg_menu{
	position: fixed;
	width: 60vw;
	height: 100vh;
	top: 0;
	right: -10vw;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	@include mq('small') {
		display: none;
	}
}