.page-template-template-information,
.page-template-single-information {

	.l-container {

		max-width: none;
		justify-content: flex-end;


		@include mq(medium) {
			display: block;
		}

		.l-6 {

			&:first-of-type {
				max-width: 720px;
			}
			@include mq(large) {
				width: 100%;
			}
		}

	}


	.c-informations-scroller {

		@include mq('medium') {
			width: 100%;
		}

	}

	.informations-container {
		min-height: 500px;
		max-height: 700px;
		overflow: scroll;

		@include mq('medium') {
			width: 100%;
			min-height: 0px;
			max-height: none;
		}
	}
}

.page-template-single-information {

	h1 {
		font-size: 3.5rem;
		font-weight: 400;
		line-height: 1.2em;

		@include mq('small') {
			font-size: 3rem;
		}
	}
}