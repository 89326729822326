.page-template-template-nous-joindre {

	.btns{
		display: flex;
		justify-content: space-around;
		margin-bottom: 15px;
	}
	.btn_accueil{
		background: #4865ab;
		display: block;
		padding: 10px;
		color: white;
		transition: all ease-in 0.5s;
		cursor: pointer;

		&:hover{
			background: #5075b6;
			text-decoration: none;
		}
	}

	input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), textarea {
		border-color: white;
		background: transparent;
		color: white;
		padding: 8px 15px;
		font-size: 1.4rem;

		&::-webkit-input-placeholder { /* WebKit browsers */
			color: white;
			opacity: 1;
		}
		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: white;
			opacity: 1;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: white;
			opacity: 1;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10+ */
			color: white;
			opacity: 1;
		}
	}

	textarea {
		padding: 15px;
	}

	.wpcf7-not-valid-tip {
		color: white;
	}

	.wpcf7-submit {
		background: $blue-light;
		border-radius: 5px;
		border: 1px solid darken($blue-light, 10);
		width: auto;
		padding: 10px 20px;
		margin-top: 10px;

		&:hover {
			border: 1px solid darken($blue-light, 20);
			background: darken($blue-light, 5);
		}
	}

	h2 {
		color: $orange;
		font-size: 3.5rem;
		margin-bottom: 1em;
		font-weight: 400;

		@include mq('medium') {
			font-size: 3rem;
		}

		strong {
			display: inline-block;
		}
	}

	h3 {
		color: $blue-light;
		font-size: 1.6rem;
		font-family: $montserrat;
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 2em;
	}
}