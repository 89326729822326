/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
/* =======================================================================
Variables
========================================================================== */
/* =======================================================================
Helpers
========================================================================== */
/* line 5, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-centered {
  text-align: center;
}

/* line 9, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-clearfix:before,
.h-clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 15, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-clearfix:after {
  clear: both;
}

/* line 19, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-clearfix {
  *zoom: 1;
}

/* line 23, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 32, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 43, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

/* line 51, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

/* line 64, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

/* line 68, public/wp-content/themes/haufman/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-4by3 {
  padding-bottom: 75%;
}

/* =======================================================================
Global
========================================================================== */
/* line 1, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
html, body {
  font-size: 10px;
  margin: 0;
  padding: 0;
  height: 100%;
}

/* line 8, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
html,
button,
input,
select,
textarea {
  color: #333;
  font-family: "Roboto", sans-serif, Arial, Verdana, sans-serif;
}

/* line 17, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
body {
  font-size: 16px;
  line-height: 1.4;
  background: url("../images/bg-texture.jpg") repeat;
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 26, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
body.in {
  opacity: 1;
}

/* line 30, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
body.out {
  opacity: 0;
}

/* line 35, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
p {
  font-size: 1.4rem;
}

/* line 39, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
h1 {
  font-size: 6rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1em;
}

/* line 46, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
h1 em {
  font-family: "Roboto", sans-serif;
  font-style: italic;
  display: block;
  font-size: 3rem;
  line-height: 1.3em;
}

/* line 54, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
h1 strong {
  font-weight: 700;
  display: block;
}

/* line 60, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
h2 {
  font-size: 4rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.1em;
}

/* line 67, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
h2 em {
  font-family: "Roboto", sans-serif;
  font-style: italic;
  display: block;
  font-size: 3rem;
  line-height: 1.4em;
}

/* line 75, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
h2 strong {
  font-weight: 700;
  display: block;
}

/* line 85, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
a {
  color: #f18c14;
  text-decoration: none;
}

/* line 88, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
a:hover {
  color: #965609;
}

/* line 89, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
a:focus, a:active {
  color: #361f03;
}

/* line 93, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
img {
  vertical-align: middle;
  max-width: 100%;
}

/* line 95, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* line 104, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* line 109, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
textarea {
  resize: vertical;
}

/* line 113, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
.visuallyhidden {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
}

/* line 126, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
.clearfix {
  clear: both;
  float: none !important;
}

/* line 131, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
.lb-details {
  display: none;
}

/* line 135, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
.wow {
  visibility: hidden;
}

/* line 139, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
::-moz-selection {
  background: black;
  color: white;
  text-shadow: none;
}

/* line 145, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
::selection {
  background: black;
  color: white;
  text-shadow: none;
}

/* line 152, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: black;
  opacity: 1;
}

/* line 156, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: black;
  opacity: 1;
}

/* line 160, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: black;
  opacity: 1;
}

/* line 164, public/wp-content/themes/haufman/assets/src/scss/global/_base.scss */
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: black;
  opacity: 1;
}

/* =======================================================================
Forms
========================================================================== */
/* line 5, public/wp-content/themes/haufman/assets/src/scss/global/_forms.scss */
input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), textarea {
  border: 1px solid #ccc;
  outline: none;
  transition: all 300ms ease;
  padding: 5px 10px;
  margin: 5px 0px;
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
}

/* line 15, public/wp-content/themes/haufman/assets/src/scss/global/_forms.scss */
input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):focus, textarea:focus {
  border: 1px solid #f18c14;
}

/* line 20, public/wp-content/themes/haufman/assets/src/scss/global/_forms.scss */
.wpcf7-submit {
  border: 1px solid #429bdc;
  background: #6db2e4;
  padding: 10px 20px;
  display: block;
  width: auto;
  color: white;
  max-width: 600px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  outline: none;
  border-radius: 5px;
  margin-top: 10px;
}

/* line 36, public/wp-content/themes/haufman/assets/src/scss/global/_forms.scss */
.wpcf7-submit:hover {
  border: 1px solid #2582c6;
  background: #57a7e0;
}

/* line 42, public/wp-content/themes/haufman/assets/src/scss/global/_forms.scss */
div.wpcf7-validation-errors {
  background: #c0392b;
  color: white;
  max-width: 600px;
  padding: 15px 20px;
  border: none;
  margin: 0px;
  text-align: center;
}

/* line 52, public/wp-content/themes/haufman/assets/src/scss/global/_forms.scss */
div.wpcf7-mail-sent-ok {
  background: #27ae60;
  color: white;
  max-width: 600px;
  padding: 15px 20px;
  border: none;
  margin: 0px;
  text-align: center;
}

/* =======================================================================
Layout
========================================================================== */
/* line 5, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
.l-container {
  clear: both;
  max-width: 1440px;
  margin: 0 auto;
}

/* line 10, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
.l-container.flex {
  display: flex;
}

/* line 14, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
.l-container h1 {
  color: #f18c14;
  font-size: 5rem;
  margin-bottom: 1em;
}

@media (max-width: 992px) {
  /* line 14, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
  .l-container h1 {
    font-size: 3rem;
  }
}

/* line 23, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
.l-container h1 strong {
  display: inline-block;
}

/* line 28, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
.l-container h2 {
  color: #6db2e4;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 2em;
}

/* line 37, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
.l-container p, .l-container li {
  font-family: "Roboto", sans-serif;
  color: #666;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6em;
}

/* line 46, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
.l-12 {
  width: 100%;
  padding: 40px 80px;
}

@media (max-width: 992px) {
  /* line 46, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
  .l-12 {
    padding: 30px 20px;
  }
}

/* line 55, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
.l-6 {
  width: 50%;
  float: left;
  padding: 40px;
}

@media (max-width: 992px) {
  /* line 55, public/wp-content/themes/haufman/assets/src/scss/global/_layout.scss */
  .l-6 {
    padding: 30px 20px;
  }
}

/* =======================================================================
Navigation
========================================================================== */
/* =======================================================================
Components
========================================================================== */
/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-maps.scss */
.c-map {
  height: 500px;
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer {
  background: linear-gradient(#6db2e4, #4866ab);
}

/* line 4, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer > div {
  max-width: 1200px;
  margin: 0 auto;
}

/* line 9, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer p {
  color: white;
  font-size: 1.4rem;
  line-height: 1.6em;
}

/* line 15, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer a {
  color: white;
  text-decoration: underline;
  transition: all 300ms ease-in-out;
}

/* line 20, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer a:hover {
  color: white;
  opacity: 0.8;
}

/* line 27, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__col {
  width: 30%;
}

@media (max-width: 992px) {
  /* line 27, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
  .c-footer__col {
    width: 100%;
  }
}

/* line 35, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__col:nth-of-type(2) {
  width: 40%;
}

@media (max-width: 992px) {
  /* line 35, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
  .c-footer__col:nth-of-type(2) {
    width: 100%;
  }
}

/* line 42, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__col:nth-of-type(2) .c-footer__title {
  padding: 0px 15px;
}

@media (max-width: 992px) {
  /* line 47, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
  .c-footer__col:nth-of-type(3) {
    width: 100%;
  }
}

/* line 55, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__row {
  display: flex;
}

@media (max-width: 992px) {
  /* line 55, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
  .c-footer__row {
    display: block;
  }
}

/* line 63, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__title {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* line 70, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__boxed {
  background: linear-gradient(#73b6e4, #516daf);
  padding: 5px 40px;
}

@media (max-width: 992px) {
  /* line 70, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
  .c-footer__boxed {
    padding: 5px 15px;
  }
}

/* line 81, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__table p {
  font-size: 1.4rem;
}

/* line 84, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__table .row {
  margin: 0px;
}

/* line 87, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__table .c-footer__table__row:nth-of-type(2n+1) {
  background: rgba(255, 255, 255, 0.1);
}

@media (max-width: 992px) {
  /* line 87, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
  .c-footer__table .c-footer__table__row:nth-of-type(2n+1) {
    background: transparent;
  }
}

/* line 96, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__table-title {
  text-transform: uppercase;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.05);
}

@media (max-width: 992px) {
  /* line 96, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
  .c-footer__table-title {
    background: transparent;
  }
  /* line 104, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
  .c-footer__table-title p {
    margin-bottom: -10px;
  }
}

/* line 112, public/wp-content/themes/haufman/assets/src/scss/components/_c-footer.scss */
.c-footer__notice p {
  font-size: 1.4rem;
  line-height: 1.6em;
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header {
  height: 0px;
  position: relative;
  z-index: 4;
}

/* line 7, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header--with-bg {
  background: url("../images/header.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 300px;
  position: relative;
}

@media (max-width: 992px) {
  /* line 7, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
  .c-header--with-bg {
    height: 200px;
  }
}

/* line 19, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header--with-bg .c-header__mobile-btn {
  color: white;
}

/* line 22, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header--with-bg .c-header__mobile-btn:before {
  background-image: url("../images/mobile-nav-white.png");
}

/* line 26, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header--with-bg .c-header__mobile-btn:hover {
  color: white;
}

/* line 32, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header--contact {
  background-image: url("../images/header-highres.jpg");
  height: auto;
  padding-top: 175px;
  padding-bottom: 60px;
}

@media (max-width: 992px) {
  /* line 32, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
  .c-header--contact {
    padding-bottom: 0px;
  }
}

/* line 43, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header__logo {
  position: absolute;
  top: 30px;
  right: 40px;
}

/* line 48, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header__logo img {
  width: 120px;
}

@media (max-width: 992px) {
  /* line 48, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
  .c-header__logo img {
    width: 90px;
  }
}

@media (max-width: 992px) {
  /* line 43, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
  .c-header__logo {
    right: 20px;
  }
}

/* line 61, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header__mobile-btn {
  position: absolute;
  top: 40px;
  left: 40px;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  outline: none;
  transition: all 300ms ease-in-out;
}

/* line 72, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header__mobile-btn:hover {
  color: #7bbee9;
  text-decoration: none;
}

/* line 76, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header__mobile-btn:hover:before {
  margin-right: 7px;
}

/* line 81, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header__mobile-btn:before {
  content: "";
  background-image: url("../images/mobile-nav.png");
  width: 25px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 5px;
  transition: all 300ms ease-in-out;
}

@media (max-width: 992px) {
  /* line 61, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
  .c-header__mobile-btn {
    left: 20px;
  }
}

/* line 98, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header__content {
  max-width: 700px;
  margin-left: auto;
  margin-right: 40px;
  border-radius: 5px;
  background: linear-gradient(rgba(241, 142, 23, 0.9), rgba(241, 142, 23, 0.9));
  padding: 60px 40px 20px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
  /* line 98, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
  .c-header__content {
    max-width: none;
    width: 100%;
    margin: 0px;
    border-radius: 0px;
  }
}

/* line 115, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
.c-header__content h1 {
  margin-top: 0px;
  font-size: 3.5rem;
  color: white;
}

@media (max-width: 992px) {
  /* line 115, public/wp-content/themes/haufman/assets/src/scss/components/_c-header.scss */
  .c-header__content h1 {
    font-size: 3rem;
  }
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu {
  background: rgba(123, 190, 233, 0.98);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  padding-left: 140px;
  padding-top: 120px;
  transform: translateY(-100%) scale(2);
  opacity: 0;
  transition: all 300ms ease-in-out, transform 600ms cubic-bezier(1, 0, 0, 1);
  pointer-events: none;
  overflow-y: auto;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
  .c-mobile-menu {
    padding-left: 40px;
    padding-top: 100px;
  }
}

/* line 24, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu.active {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0%) scale(1);
}

/* line 29, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu.active:before {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 700ms;
}

/* line 35, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu.active .c-mobile-menu__close {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 500ms;
}

/* line 42, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu:before {
  content: "";
  background-image: url("../images/logo-white.png");
  position: absolute;
  right: 140px;
  top: 40px;
  height: 90px;
  width: 90px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 500ms ease-in-out;
  outline: none;
  opacity: 0;
  transform: translateY(-30px);
  transition-delay: 0ms;
}

@media (max-width: 992px) {
  /* line 42, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
  .c-mobile-menu:before {
    right: 40px;
  }
}

/* line 64, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu ul {
  padding-left: 0px;
  margin-top: -40px;
}

/* line 69, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu li {
  list-style: none;
}

/* line 72, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu li.phone {
  margin-top: 2em;
}

/* line 77, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu li.current-menu-item a {
  color: #4763a9;
}

/* line 81, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 3.5vw;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  line-height: 1.3em;
  transition: all 250ms ease-in-out;
}

@media (max-width: 767px) {
  /* line 81, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
  .c-mobile-menu li a {
    font-size: 2.8rem;
  }
}

@media (min-width: 1199px) {
  /* line 81, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
  .c-mobile-menu li a {
    font-size: 3vw;
  }
}

/* line 99, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu li a:hover {
  color: #304373;
}

/* line 105, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu__close {
  position: absolute;
  top: 40px;
  left: 140px;
  background: transparent;
  border: none;
  text-transform: uppercase;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  transition: all 400ms ease-in-out;
  outline: none;
  opacity: 0;
  transform: translateY(-30px);
  transition-delay: 0ms;
}

@media (max-width: 992px) {
  /* line 105, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
  .c-mobile-menu__close {
    left: 40px;
  }
}

/* line 125, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu__close:hover {
  opacity: 0.8;
}

/* line 128, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu__close:hover:before {
  margin-right: 10px;
  transform: rotate(90deg);
}

/* line 134, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.c-mobile-menu__close:before {
  content: "";
  display: inline-block;
  background-image: url("../images/close.png");
  height: 15px;
  width: 15px;
  margin-right: 5px;
  background-size: contain;
  transition: all 300ms ease-in-out;
}

/* line 146, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
.bg_menu {
  position: fixed;
  width: 60vw;
  height: 100vh;
  top: 0;
  right: -10vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-width: 767px) {
  /* line 146, public/wp-content/themes/haufman/assets/src/scss/components/_c-mobile-menu.scss */
  .bg_menu {
    display: none;
  }
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
.c-team-member {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
  .c-team-member {
    display: flex;
    flex-direction: column;
  }
  /* line 9, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
  .c-team-member:nth-of-type(2n+1) {
    flex-direction: column-reverse;
  }
  /* line 13, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
  .c-team-member .l-6 {
    width: 100%;
  }
}

/* line 19, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
h2.c-team-member__name {
  color: #f18c14;
  font-size: 4rem;
  margin-bottom: 0em;
  font-weight: 400;
  margin-top: 1em;
}

@media (max-width: 992px) {
  /* line 19, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
  h2.c-team-member__name {
    font-size: 3rem;
  }
  /* line 29, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
  h2.c-team-member__name strong {
    display: inline;
  }
}

/* line 35, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
h3.c-team-member__position {
  color: #6db2e4;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 2em;
}

/* line 45, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
.c-team-member__image {
  background-color: #6db2e4;
  min-height: 540px;
  background-size: cover;
}

@media (max-width: 992px) {
  /* line 45, public/wp-content/themes/haufman/assets/src/scss/components/_c-team-member.scss */
  .c-team-member__image {
    min-height: 400px;
  }
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-clinique-carousel.scss */
.c-clinique-carousel {
  margin: 40px 0px;
  position: relative;
}

/* line 6, public/wp-content/themes/haufman/assets/src/scss/components/_c-clinique-carousel.scss */
.c-clinique-carousel__slide {
  padding: 20px;
}

/* line 10, public/wp-content/themes/haufman/assets/src/scss/components/_c-clinique-carousel.scss */
.c-clinique-carousel__image {
  height: 240px !important;
  display: block;
  width: auto;
  background-size: cover;
  background-position: 50% 50%;
}

@media (max-width: 992px) {
  /* line 10, public/wp-content/themes/haufman/assets/src/scss/components/_c-clinique-carousel.scss */
  .c-clinique-carousel__image {
    height: 200px;
  }
}

/* line 22, public/wp-content/themes/haufman/assets/src/scss/components/_c-clinique-carousel.scss */
.c-clinique-carousel__prev,
.c-clinique-carousel__next {
  border: 1px solid #2c90d8;
  background: #6db2e4;
  color: white;
  height: 40px;
  width: 40px;
  transform: scale(1);
  transition: all 300ms ease;
  position: absolute;
  top: calc(50% - 20px);
  z-index: 2;
}

/* line 35, public/wp-content/themes/haufman/assets/src/scss/components/_c-clinique-carousel.scss */
.c-clinique-carousel__prev:hover,
.c-clinique-carousel__next:hover {
  transform: scale(1.1);
  background: #429bdc;
  color: white;
}

/* line 43, public/wp-content/themes/haufman/assets/src/scss/components/_c-clinique-carousel.scss */
.c-clinique-carousel__prev {
  left: 0px;
}

/* line 47, public/wp-content/themes/haufman/assets/src/scss/components/_c-clinique-carousel.scss */
.c-clinique-carousel__next {
  right: 0px;
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
.c-informations-scroller {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
}

/* line 10, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
.c-informations-scroller__box {
  width: 50%;
}

@media (max-width: 767px) {
  /* line 10, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
  .c-informations-scroller__box {
    width: 100%;
    border: none !important;
    background: white;
  }
}

/* line 19, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
.c-informations-scroller__box:nth-of-type(2n+1) {
  border-right: 1px solid white;
  background: white;
}

/* line 25, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
.c-informations-scroller__header {
  padding: 30px 20px;
  background: linear-gradient(#6db2e4, #4866ab);
}

/* line 29, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
.c-informations-scroller__header h2 {
  color: white;
  text-align: center;
  margin: 0px;
  font-size: 2rem;
  letter-spacing: 1px;
  font-weight: 300;
}

/* line 40, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
.c-informations-scroller__content {
  padding: 20px;
}

/* line 43, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
.c-informations-scroller__content ul {
  padding: 0px 20px;
}

/* line 47, public/wp-content/themes/haufman/assets/src/scss/components/_c-informations-scroller.scss */
.c-informations-scroller__content .current-menu-item a {
  font-weight: bold;
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-more-informations.scss */
.c-more-informations {
  text-align: center;
  padding: 0px 200px;
  margin: 80px 0px;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-more-informations.scss */
  .c-more-informations {
    padding: 0px 40px;
  }
}

/* line 10, public/wp-content/themes/haufman/assets/src/scss/components/_c-more-informations.scss */
.c-more-informations > div {
  width: 50%;
  float: left;
  text-align: left;
  padding: 0px 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  /* line 10, public/wp-content/themes/haufman/assets/src/scss/components/_c-more-informations.scss */
  .c-more-informations > div {
    padding: 0px;
    width: 100%;
    float: none;
    text-align: center;
  }
}

/* line 24, public/wp-content/themes/haufman/assets/src/scss/components/_c-more-informations.scss */
.c-more-informations > div img {
  max-width: 60px;
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section {
  height: 100%;
  padding-top: 130px;
  min-height: 500px;
  position: relative;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section {
    padding-top: 80px;
    display: block;
  }
}

/* line 15, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section:before {
  content: "";
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 375px;
  background: linear-gradient(#6db2e4, #4866ab);
  z-index: -1;
}

@media (max-width: 992px) {
  /* line 15, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section:before {
    width: 200px;
  }
}

@media (max-width: 767px) {
  /* line 15, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section:before {
    width: 130px;
  }
}

/* line 34, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section .slick-dots {
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
  list-style: none;
}

/* line 41, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section .slick-dots li {
  opacity: 0.8;
  transition: all 300ms ease-in-out;
  margin: 15px 0px;
  position: relative;
}

/* line 47, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section .slick-dots li:after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -3px;
  left: 0px;
  background: white;
  opacity: 0;
  transform: translateY(5px);
  transition: all 300ms ease-in-out;
}

/* line 61, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section .slick-dots .slick-active {
  opacity: 1;
}

/* line 64, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section .slick-dots .slick-active:after {
  transform: translateY(0px);
  opacity: 1;
}

/* line 70, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section .slick-dots button {
  background: none;
  color: white;
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
}

/* line 79, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section .slick-dots button:before {
  content: ".0";
}

/* line 85, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section__slider {
  width: 100%;
  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 992px) {
  /* line 85, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__slider {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  /* line 85, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__slider {
    margin: 20px 0px;
  }
}

/* line 99, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section__slide {
  height: 650px !important;
  max-height: 100%;
  background-size: cover;
  border: none !important;
}

@media (max-width: 1199px) {
  /* line 99, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__slide {
    height: 350px !important;
  }
}

/* line 110, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section__left {
  width: 35%;
  float: left;
  padding: 80px 20px 40px 70px;
}

@media (max-width: 1199px) {
  /* line 110, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__left {
    width: 30%;
    padding: 40px 20px 40px 20px;
  }
  /* line 123, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__left h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 767px) {
  /* line 110, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__left {
    width: 75%;
  }
}

/* line 132, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section__left h1 em {
  color: #f18c14;
}

/* line 137, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section__right {
  width: 60vw;
  height: 57vh;
  top: -10vh;
  float: left;
  position: relative;
}

@media (max-width: 1199px) {
  /* line 137, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__right {
    top: 0;
  }
}

@media (max-width: 767px) {
  /* line 137, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__right {
    display: none;
  }
}

/* line 154, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
.c-home-section__right:before {
  content: "";
  height: 1px;
  width: 80px;
  background: #6db2e4;
  position: absolute;
  left: -50px;
  top: 85%;
  z-index: 2;
}

@media (max-width: 1199px) {
  /* line 154, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__right:before {
    top: 65%;
  }
}

@media (max-width: 992px) {
  /* line 154, public/wp-content/themes/haufman/assets/src/scss/components/_c-home-section.scss */
  .c-home-section__right:before {
    top: 75%;
  }
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section {
  margin-top: 100px;
  margin-bottom: 140px;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
  .c-about-section {
    margin: 40px 0px;
  }
}

/* line 11, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section.image-on-right .c-about-section__content, .c-about-section.image-on-right .c-about-section__content--white {
  right: -100px;
  left: auto;
  z-index: 3;
}

/* line 19, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section__content, .c-about-section__content--white {
  background: #f18c14;
  padding: 40px 50px 80px 50px;
  color: white;
  position: relative;
  left: -100px;
  margin-top: 200px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 992px) {
  /* line 19, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
  .c-about-section__content, .c-about-section__content--white {
    position: static;
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  /* line 19, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
  .c-about-section__content, .c-about-section__content--white {
    padding: 40px 30px 40px 30px;
  }
}

/* line 38, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section__content:before, .c-about-section__content--white:before {
  content: "";
  height: 1px;
  width: 80px;
  background: #6db2e4;
  position: absolute;
  left: -50px;
  top: 85%;
  z-index: 2;
}

/* line 49, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section__content a, .c-about-section__content--white a {
  color: white;
  font-size: 1.4rem;
}

/* line 54, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section__content--white {
  background: white;
  color: #000;
}

/* line 59, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section__content--white h2 {
  color: #6db2e4;
}

/* line 62, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section__content--white h2 em {
  color: #f18c14;
}

/* line 67, public/wp-content/themes/haufman/assets/src/scss/components/_c-about-section.scss */
.c-about-section__content--white a {
  color: #f18c14;
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider {
  position: relative;
}

/* line 7, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slick .col-md-6:before {
  content: attr(data-slide-number);
  font-size: 350px;
  color: #cccccc;
  font-family: "Playfair Display", serif;
  position: absolute;
  z-index: 0;
  top: -100px;
  left: -100px;
  transform: translateY(100px);
  transition: all 1200ms ease;
  transition-delay: 0ms;
  opacity: 0;
}

@media (max-width: 992px) {
  /* line 7, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__slick .col-md-6:before {
    left: -50px;
  }
}

@media (max-width: 767px) {
  /* line 7, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__slick .col-md-6:before {
    display: none;
  }
}

/* line 30, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slick .slick-dots {
  position: absolute;
  top: 50%;
  left: calc(50% - 30px);
  transform: translateY(-50%);
  list-style: none;
}

@media (max-width: 992px) {
  /* line 30, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__slick .slick-dots {
    left: auto;
    right: 20px;
  }
}

@media (max-width: 767px) {
  /* line 30, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__slick .slick-dots {
    left: auto;
    right: 40px;
  }
}

/* line 47, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slick .slick-dots li {
  opacity: 0.8;
  transition: all 300ms ease-in-out;
  margin: 15px 0px;
  position: relative;
}

/* line 53, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slick .slick-dots li:after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -3px;
  left: 0px;
  background: black;
  opacity: 0;
  transform: translateY(5px);
  transition: all 300ms ease-in-out;
}

/* line 67, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slick .slick-dots .slick-active {
  opacity: 1;
}

/* line 70, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slick .slick-dots .slick-active:after {
  transform: translateY(0px);
  opacity: 1;
}

/* line 76, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slick .slick-dots button {
  background: none;
  color: black;
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
}

/* line 85, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slick .slick-dots button:before {
  content: ".0";
}

/* line 92, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide {
  background-size: cover;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  border: none !important;
}

/* line 100, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide.slick-current .c-services-slider__content {
  transform: translateY(0px);
  transition-delay: 0ms;
  opacity: 1;
}

/* line 106, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide.slick-current .col-md-6:before {
  transform: translateY(0px);
  transition-delay: 0ms;
  opacity: 0.8;
}

@media (max-width: 767px) {
  /* line 92, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__slide {
    background-position: right center;
  }
}

/* line 117, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide:nth-child(2) .c-services-slider__content {
  background: #7bbee9;
}

/* line 120, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide:nth-child(2) .c-services-slider__content h2 {
  color: white;
}

/* line 123, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide:nth-child(2) .c-services-slider__content h2 em {
  color: #f18c14;
}

/* line 129, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide:nth-child(3) .c-services-slider__content {
  background: white;
}

/* line 132, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide:nth-child(3) .c-services-slider__content h2 {
  color: #7bbee9;
}

/* line 135, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide:nth-child(3) .c-services-slider__content h2 em {
  color: #f18c14;
}

/* line 140, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__slide:nth-child(3) .c-services-slider__content a {
  color: #7bbee9;
}

/* line 148, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__content {
  background: #f18c14;
  padding: 120px 50px 40px 50px;
  margin: 100px 0px;
  color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-150px);
  opacity: 0;
  transition: all 800ms cubic-bezier(0.115, 0.725, 0.58, 0.965);
  transition-delay: 500ms;
  position: relative;
  z-index: 2;
}

/* line 162, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
.c-services-slider__content a {
  color: white;
  font-size: 1.4rem;
}

@media (min-width: 767px) and (max-width: 1024px) {
  /* line 148, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__content {
    margin: 0;
    padding: 20px;
  }
}

@media (max-width: 992px) {
  /* line 148, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__content {
    margin: 80px 40px;
  }
}

@media (max-width: 767px) {
  /* line 148, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__content {
    margin: 0px;
    padding: 80px 30px 40px 30px;
  }
  /* line 180, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__content h2 {
    font-size: 2rem;
  }
  /* line 183, public/wp-content/themes/haufman/assets/src/scss/components/_c-services-slider.scss */
  .c-services-slider__content h2 em {
    font-size: 1.5rem;
  }
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-scroll-btn.scss */
.c-scroll-btn {
  color: black;
  text-transform: uppercase;
  font-size: 1rem;
  position: absolute;
  bottom: 80px;
  left: 60px;
  transform: rotate(90deg);
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-scroll-btn.scss */
  .c-scroll-btn {
    left: 20px;
  }
}

/* line 15, public/wp-content/themes/haufman/assets/src/scss/components/_c-scroll-btn.scss */
.c-scroll-btn:before {
  content: "";
  display: block;
  height: 1px;
  width: 30px;
  background: #777;
  position: absolute;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateX(0px);
  opacity: 0.6;
  transition: all 300ms ease;
}

/* line 29, public/wp-content/themes/haufman/assets/src/scss/components/_c-scroll-btn.scss */
.c-scroll-btn:hover {
  text-decoration: none;
}

/* line 33, public/wp-content/themes/haufman/assets/src/scss/components/_c-scroll-btn.scss */
.c-scroll-btn:hover:before {
  opacity: 1;
  transform: translateX(-10px);
}

/* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-scrollbox.scss */
.c-scrollbox {
  max-height: 800px;
  overflow-y: auto;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/haufman/assets/src/scss/components/_c-scrollbox.scss */
  .c-scrollbox {
    max-height: none;
  }
}

/* =======================================================================
Templates
========================================================================== */
/* line 1, public/wp-content/themes/haufman/assets/src/scss/templates/_t-home.scss */
.page-template-template-accueil {
  height: 100%;
}

/* line 4, public/wp-content/themes/haufman/assets/src/scss/templates/_t-home.scss */
.page-template-template-accueil .btns {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

/* line 9, public/wp-content/themes/haufman/assets/src/scss/templates/_t-home.scss */
.page-template-template-accueil .btn_accueil {
  background: #4865ab;
  display: block;
  padding: 10px;
  color: white;
  transition: all ease-in 0.5s;
  cursor: pointer;
}

/* line 17, public/wp-content/themes/haufman/assets/src/scss/templates/_t-home.scss */
.page-template-template-accueil .btn_accueil:hover {
  background: #5075b6;
  text-decoration: none;
}

/* line 4, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
.page-template-template-services .l-container,
.single-dental_services .l-container {
  max-width: none;
  justify-content: flex-end;
}

@media (max-width: 992px) {
  /* line 4, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
  .page-template-template-services .l-container,
  .single-dental_services .l-container {
    display: block;
  }
}

/* line 16, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
.page-template-template-services .l-container .l-6:first-of-type,
.single-dental_services .l-container .l-6:first-of-type {
  max-width: 720px;
  margin-left: 3vw;
}

@media (max-width: 1199px) {
  /* line 14, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
  .page-template-template-services .l-container .l-6,
  .single-dental_services .l-container .l-6 {
    width: 100%;
  }
}

/* line 26, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
.page-template-template-services .vzarr-clip,
.single-dental_services .vzarr-clip {
  margin-bottom: 20px;
}

/* line 30, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
.page-template-template-services .background-image-container,
.single-dental_services .background-image-container {
  background-size: cover;
  background-position: 0px 0%;
  min-height: 300px;
}

@media (max-width: 1199px) {
  /* line 30, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
  .page-template-template-services .background-image-container,
  .single-dental_services .background-image-container {
    min-height: 500px;
  }
}

/* line 40, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
.page-template-template-services .list-services,
.single-dental_services .list-services {
  width: 100%;
}

/* line 44, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
.page-template-template-services .serv_container,
.single-dental_services .serv_container {
  display: flex;
  flex-flow: row wrap;
}

/* line 48, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
.page-template-template-services .serv_container .serv_bloc,
.single-dental_services .serv_container .serv_bloc {
  flex-basis: 50%;
}

@media (max-width: 992px) {
  /* line 48, public/wp-content/themes/haufman/assets/src/scss/templates/_t-services.scss */
  .page-template-template-services .serv_container .serv_bloc,
  .single-dental_services .serv_container .serv_bloc {
    flex-basis: 100%;
  }
}

/* line 4, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
.page-template-template-information .l-container,
.page-template-single-information .l-container {
  max-width: none;
  justify-content: flex-end;
}

@media (max-width: 992px) {
  /* line 4, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
  .page-template-template-information .l-container,
  .page-template-single-information .l-container {
    display: block;
  }
}

/* line 16, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
.page-template-template-information .l-container .l-6:first-of-type,
.page-template-single-information .l-container .l-6:first-of-type {
  max-width: 720px;
}

@media (max-width: 1199px) {
  /* line 14, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
  .page-template-template-information .l-container .l-6,
  .page-template-single-information .l-container .l-6 {
    width: 100%;
  }
}

@media (max-width: 992px) {
  /* line 27, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
  .page-template-template-information .c-informations-scroller,
  .page-template-single-information .c-informations-scroller {
    width: 100%;
  }
}

/* line 35, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
.page-template-template-information .informations-container,
.page-template-single-information .informations-container {
  min-height: 500px;
  max-height: 700px;
  overflow: scroll;
}

@media (max-width: 992px) {
  /* line 35, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
  .page-template-template-information .informations-container,
  .page-template-single-information .informations-container {
    width: 100%;
    min-height: 0px;
    max-height: none;
  }
}

/* line 50, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
.page-template-single-information h1 {
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.2em;
}

@media (max-width: 767px) {
  /* line 50, public/wp-content/themes/haufman/assets/src/scss/templates/_t-informations.scss */
  .page-template-single-information h1 {
    font-size: 3rem;
  }
}

/* line 3, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre .btns {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

/* line 8, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre .btn_accueil {
  background: #4865ab;
  display: block;
  padding: 10px;
  color: white;
  transition: all ease-in 0.5s;
  cursor: pointer;
}

/* line 16, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre .btn_accueil:hover {
  background: #5075b6;
  text-decoration: none;
}

/* line 22, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), .page-template-template-nous-joindre textarea {
  border-color: white;
  background: transparent;
  color: white;
  padding: 8px 15px;
  font-size: 1.4rem;
}

/* line 29, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::-webkit-input-placeholder, .page-template-template-nous-joindre textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: white;
  opacity: 1;
}

/* line 33, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):-moz-placeholder, .page-template-template-nous-joindre textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: white;
  opacity: 1;
}

/* line 37, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::-moz-placeholder, .page-template-template-nous-joindre textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: white;
  opacity: 1;
}

/* line 41, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):-ms-input-placeholder, .page-template-template-nous-joindre textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: white;
  opacity: 1;
}

/* line 47, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre textarea {
  padding: 15px;
}

/* line 51, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre .wpcf7-not-valid-tip {
  color: white;
}

/* line 55, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre .wpcf7-submit {
  background: #6db2e4;
  border-radius: 5px;
  border: 1px solid #429bdc;
  width: auto;
  padding: 10px 20px;
  margin-top: 10px;
}

/* line 63, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre .wpcf7-submit:hover {
  border: 1px solid #2582c6;
  background: #57a7e0;
}

/* line 69, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre h2 {
  color: #f18c14;
  font-size: 3.5rem;
  margin-bottom: 1em;
  font-weight: 400;
}

@media (max-width: 992px) {
  /* line 69, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
  .page-template-template-nous-joindre h2 {
    font-size: 3rem;
  }
}

/* line 79, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre h2 strong {
  display: inline-block;
}

/* line 84, public/wp-content/themes/haufman/assets/src/scss/templates/_t-contact.scss */
.page-template-template-nous-joindre h3 {
  color: #6db2e4;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 2em;
}

/* line 3, public/wp-content/themes/haufman/assets/src/scss/templates/_t-clinique.scss */
.page-template-template-clinique .l-12 {
  padding: 40px;
}

@media (max-width: 992px) {
  /* line 3, public/wp-content/themes/haufman/assets/src/scss/templates/_t-clinique.scss */
  .page-template-template-clinique .l-12 {
    padding: 40px 20px;
  }
}
