.c-home-section {
	height: 100%;
	padding-top: 130px;
	min-height: 500px;
	position: relative;
	display: flex;
	align-items: center;

	@include mq('small') {
		padding-top: 80px;
		display: block;
	}


	&:before {
		content: "";
		height: 100%;
		position: absolute;
		right: 0px;
		top: 0px;
		width: 375px;
		background: linear-gradient($blue-light, $blue-dark);
		z-index: -1;

		@include mq('medium') {
			width: 200px;
		}

		@include mq('small') {
			width: 130px;
		}
	}

	.slick-dots {
		position: absolute;
		top: 50%;
		right: -50px;
		transform: translateY(-50%);
		list-style: none;

		li {
			opacity: 0.8;
			transition: all 300ms ease-in-out;
			margin: 15px 0px;
			position: relative;

			&:after {
				content: "";
				height: 1px;
				width: 100%;
				position: absolute;
				bottom: -3px;
				left: 0px;
				background: white;
				opacity: 0;
				transform: translateY(5px);
				transition: all 300ms ease-in-out;
			}
		}

		.slick-active {
			opacity: 1;

			&:after {
				transform: translateY(0px);
				opacity: 1;
			}
		}

		button {
			background: none;
			color: white;
			border: none;
			outline: none;
			margin: 0px;
			padding: 0px;
		}

		button:before {
			content: ".0";
		}
	}
}

.c-home-section__slider {
	width: 100%;
	border: none;
	box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
	
	@include mq('medium') {
		margin-top: 60px;
	}
	
	@include mq('small') {
		margin: 20px 0px;
	}
}

.c-home-section__slide {
	height: 650px !important;
	max-height: 100%;
	background-size: cover;
	border: none !important;

	@include mq('large') {
		height: 350px !important;
	}
}

.c-home-section__left {
	width: 35%;
	float: left;
	padding: 80px 20px 40px 70px;

	// @include mq('medium') {

	// }

	@include mq('large') {
		width: 30%;
		padding: 40px 20px 40px 20px;

		h1 {
			font-size: 3.5rem;
		}
	}

	@include mq('small') {
		width: 75%;
	}

	h1 em {
		color: $orange;
	}
}

.c-home-section__right {
	width: 60vw;
	height: 57vh;
	top: -10vh;
	float: left;
	position: relative;

	@include mq('large') {
		top: 0;
	}



	@include mq('small') {
		display: none;
	}

	&:before {
		content: "";
		height: 1px;
		width: 80px;
		background: $blue-light;
		position: absolute;
		left: -50px;
		top: 85%;
		z-index: 2;

		@include mq('large') {
			top: 65%;
		}

		@include mq('medium') {
			top: 75%;
		}
	}
}