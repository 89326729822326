.c-services-slider {
	position: relative;
}

.c-services-slider__slick {

	.col-md-6:before {
		content: attr(data-slide-number);
		font-size: 350px;
		color: #cccccc;
		font-family: $playfair;
	    position: absolute;
	    z-index: 0;
	    top: -100px;
	    left: -100px;
	    transform: translateY(100px);
	    transition: all 1200ms ease;
		transition-delay: 0ms;
		opacity: 0;

		@include mq('medium') {
			left: -50px;
		}

		@include mq('small') {
			display: none;
		}
	}
	
	.slick-dots {
		position: absolute;
		top: 50%;
		left: calc(50% - 30px);
		transform: translateY(-50%);
		list-style: none;

		@include mq('medium') {
			left: auto;
			right: 20px;
		}

		@include mq('small') {
			left: auto;
			right: 40px;
		}

		li {
			opacity: 0.8;
			transition: all 300ms ease-in-out;
			margin: 15px 0px;
			position: relative;

			&:after {
				content: "";
				height: 1px;
				width: 100%;
				position: absolute;
				bottom: -3px;
				left: 0px;
				background: black;
				opacity: 0;
				transform: translateY(5px);
				transition: all 300ms ease-in-out;
			}
		}

		.slick-active {
			opacity: 1;

			&:after {
				transform: translateY(0px);
				opacity: 1;
			}
		}

		button {
			background: none;
			color: black;
			border: none;
			outline: none;
			margin: 0px;
			padding: 0px;
		}

		button:before {
			content: ".0";
		}
	}

}

.c-services-slider__slide {
	background-size: cover;
	background-position: 0% 50%;
	background-repeat: no-repeat;
	border: none !important;

	&.slick-current {

		.c-services-slider__content {
			transform: translateY(0px);
			transition-delay: 0ms;
			opacity: 1;
		}

		.col-md-6:before {
			transform: translateY(0px);
			transition-delay: 0ms;
			opacity: 0.8;
		}
	}

	@include mq('small'){
		background-position: right center;
	}

	&:nth-child(2) .c-services-slider__content{
		background: #7bbee9;

		h2{
			color: white;

			em{
				color: $orange;
			}
		}
	}

	&:nth-child(3) .c-services-slider__content{
		background: white;

		h2{
			color: #7bbee9;

			em{
				color: $orange;
			}
		}

		a{
			color: #7bbee9;
		}
	}
}



.c-services-slider__content {
	background: $orange;
	padding: 120px 50px 40px 50px;
	margin: 100px 0px;
	color: white;
	box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
	transform: translateY(-150px);
	opacity: 0;
	transition: all 800ms cubic-bezier(0.115, 0.725, 0.580, 0.965);
	transition-delay: 500ms;
	position: relative;
	z-index: 2;


	a {
		color: white;
		font-size: 1.4rem;
	}

	@media (min-width: 767px) and (max-width: 1024px){
		margin: 0;
		padding: 20px;
	}

	@include mq('medium') {
		margin: 80px 40px;
	}

	@include mq('small') {
		margin: 0px;
		padding: 80px 30px 40px 30px;

		h2{
			font-size: 2rem;

			em{
				font-size: 1.5rem;
			}
		}
	}
}