html, body {
	font-size: 10px;
	margin: 0;
	padding: 0;
	height: 100%;
}

html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-stack;
}

body {
	font-size: $font-size-base;
	line-height: $line-height-base;
	background: url('../images/bg-texture.jpg') repeat;
	opacity: 0;
	transition: opacity 350ms ease-in-out;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	&.in {
		opacity: 1;
	}

	&.out {
		opacity: 0;
	}
}

p {
	font-size: 1.4rem;
}

h1 {
	font-size: 6rem;
	font-family: $montserrat;
	text-transform: uppercase;
	font-weight: 300;
	line-height: 1em;

	em {
		font-family: $roboto;
		font-style: italic;
		display: block;
		font-size: 3rem;
		line-height: 1.3em;
	}

	strong {
		font-weight: 700;
		display: block;
	}
}

h2 {
	font-size: 4rem;
	font-family: $montserrat;
	text-transform: uppercase;
	font-weight: 300;
	line-height: 1.1em;

	em {
		font-family: $roboto;
		font-style: italic;
		display: block;
		font-size: 3rem;
		line-height: 1.4em;
	}

	strong {
		font-weight: 700;
		display: block;
	}
}

h3 {

}

a {
	color: $color-link;
	text-decoration: none;
	&:hover { color: $color-link-hover; }
	&:focus,
	&:active { color: $color-link-active; }
}

img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

.visuallyhidden {
	font-size: 0;
	width: 1px;
	height: 1px;
	display: inline-block;
	overflow: hidden;
	position: absolute!important;
	border: 0!important;
	padding: 0!important;
	margin: 0!important;
	clip: rect(1px,1px,1px,1px);
}

.clearfix {
	clear: both;
	float: none !important;
}

.lb-details {
	display: none;
}

.wow {
	visibility: hidden;
}
// Selection
::-moz-selection {
	background: black;
	color: white;
	text-shadow: none;
}

::selection {
	background: black;
	color: white;
	text-shadow: none;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: black;
	opacity: 1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: black;
	opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: black;
	opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: black;
	opacity: 1;
}
