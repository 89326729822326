.c-more-informations {
	text-align: center;
	padding: 0px 200px;
	margin: 80px 0px;

	@include mq('medium') {
		padding: 0px 40px;
	}

	& > div {
		width: 50%;
		float: left;
		text-align: left;
		padding: 0px 20px;
		margin-bottom: 20px;

		@include mq('small') {
			padding: 0px;
			width: 100%;
			float: none;
			text-align: center;
		}

		img {
			max-width: 60px;
		}
	}
}