.c-team-member {
	display: flex;
	align-items: center;
	
	@include mq('small') {
		display: flex;
		flex-direction: column;

		&:nth-of-type(2n+1) {
			flex-direction: column-reverse;
		}

		.l-6 {
			width: 100%;
		}
	}
}

h2.c-team-member__name {
	color: $orange;
	font-size: 4rem;
	margin-bottom: 0em;
	font-weight: 400;
	margin-top: 1em;

	@include mq(medium) {
		font-size: 3rem;

		strong {
			display: inline;
		}
	}
}

h3.c-team-member__position {
	color: $blue-light;
	font-size: 1.6rem;
	font-family: $montserrat;
	text-transform: uppercase;
	font-weight: 700;
	margin-top: 1em;
	margin-bottom: 2em;
}

.c-team-member__image {
	background-color: $blue-light;
	min-height: 540px;
	background-size: cover;

	@include mq('medium') {
		min-height: 400px;
	}
}