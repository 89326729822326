.c-footer {
	background: linear-gradient($blue-light, $blue-dark);

	& > div {
		max-width: 1200px;
		margin: 0 auto;
	}

	p {
		color: white;
		font-size: 1.4rem;
		line-height: 1.6em;
	}

	a {
		color: white;
		text-decoration: underline;
		transition: all 300ms ease-in-out;

		&:hover {
			color: white;
			opacity: 0.8;
		}
	}
}

.c-footer__col {
	width: 30%;

	@include mq(medium) {
		width: 100%;
	} 
}

.c-footer__col:nth-of-type(2) {
	width: 40%;

	@include mq(medium) {
		width: 100%;
	}

	.c-footer__title {
		padding: 0px 15px;
	}
}

.c-footer__col:nth-of-type(3) {

	@include mq(medium) {
		width: 100%;
	}

}

.c-footer__row {
	display: flex;

	@include mq(medium) {
		display: block;
	}
}

.c-footer__title {
	font-size: 1.8rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.c-footer__boxed {
	background: linear-gradient(#73b6e4, #516daf);
	padding: 5px 40px;

	@include mq(medium) {
		padding: 5px 15px;
	}
}

.c-footer__table {

	p {
		font-size: 1.4rem;
	}
	.row {
		margin: 0px;
	}
	.c-footer__table__row:nth-of-type(2n+1) {
		background: rgba(white,0.10);

		@include mq(medium) {
			background: transparent;
		}
	}
}

.c-footer__table-title {
	text-transform: uppercase;
	font-weight: 700;
	background: rgba(white,0.05);

	@include mq(medium) {
		background: transparent;

		p {
			margin-bottom: -10px;
		}
	}
}

.c-footer__notice {

	p {
		font-size: 1.4rem;
		line-height: 1.6em;
	}
}