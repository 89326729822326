.page-template-template-accueil {
	height: 100%;

	.btns{
		display: flex;
		justify-content: space-around;
		margin-bottom: 15px;
	}
	.btn_accueil{
		background: #4865ab;
		display: block;
		padding: 10px;
		color: white;
		transition: all ease-in 0.5s;
		cursor: pointer;

		&:hover{
			background: #5075b6;
			text-decoration: none;
		}
	}
}