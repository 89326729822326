.page-template-template-services,
.single-dental_services {

	.l-container {

		max-width: none;
		justify-content: flex-end;


		@include mq(medium) {
			display: block;
		}

		.l-6 {

			&:first-of-type {
				max-width: 720px;
				margin-left: 3vw
			}
			@include mq(large) {
				width: 100%;
			}
		}
	}

	.vzarr-clip {
		margin-bottom: 20px;
	}

	.background-image-container {
		background-size: cover;
		background-position: 0px 0%;
		min-height: 300px;

		@include mq(large) {
			min-height: 500px;
		}
	}

	.list-services {
		width: 100%;
	}

	.serv_container{
		display: flex;
		flex-flow: row wrap;

		.serv_bloc{
			flex-basis: 50%;

			@include mq(medium){
				flex-basis: 100%;
			}
		}
	}
}