.c-about-section {
	margin-top: 100px;
	margin-bottom: 140px;

	@include mq('medium') {
		margin: 40px 0px;
	}

	&.image-on-right {

		.c-about-section__content {
			right:  -100px;
			left: auto;
			z-index: 3;
		}
	}
}

.c-about-section__content {
	background: $orange;
	padding: 40px 50px 80px 50px;
	color: white;
	position: relative;
	left: -100px;
	margin-top: 200px;
	box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
	
	@include mq('medium') {
		position: static;
		margin-top: 0px;
		//box-shadow: none;
	}

	@include mq('small') {
		padding: 40px 30px 40px 30px;
	}

	&:before {
		content: "";
		height: 1px;
		width: 80px;
		background: $blue-light;
		position: absolute;
		left: -50px;
		top: 85%;
		z-index: 2;
	}

	a {
		color: white;
		font-size: 1.4rem;
	}

	&--white {
		@extend .c-about-section__content;
		background: white;
		color: #000;

		h2 {
			color: $blue-light;

			em {
				color: $orange;
			}
		}

		a {
			color: $orange;
		}

	}
}