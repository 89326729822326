.c-informations-scroller {
	//height: 100%;
	//max-height:500px;
	overflow-y: auto;
	display: flex;
	flex-wrap: wrap;
	padding: 0px;
}

.c-informations-scroller__box {
	width: 50%;

	@include mq('small') {
		width: 100%;
		border: none !important;
		background: white;
	}

	&:nth-of-type(2n+1) {
		border-right: 1px solid white;
		background: white;
	}
}

.c-informations-scroller__header {
	padding: 30px 20px;
	background: linear-gradient($blue-light, $blue-dark);

	h2 {
		color: white;
		text-align: center;
		margin: 0px;
		font-size: 2rem;
		letter-spacing: 1px;
		font-weight: 300;
	}

}

.c-informations-scroller__content {
	padding: 20px;

	ul {
		padding: 0px 20px;
	}

	.current-menu-item a {
		font-weight: bold;
	}
}