/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 767px),
	'medium': 		(max-width: 992px),
	'large': 		(max-width: 1199px),
	'above-large': 	(min-width: 1199px),
	'xxl': 			(max-width: 1439px)
);


/* =======================================================================
Font-face
========================================================================== */

// @font-face {
// 	font-family: 'font-name-here';
// 	src: url('../fonts/font-name-here/font-name-here.ttf') format("truetype");
// }

$montserrat: 'Montserrat', sans-serif; //300,700
$roboto: 'Roboto', sans-serif; //400,400i,700
$playfair: 'Playfair Display', serif;

/* =======================================================================
Variables
========================================================================== */

// Colors
$orange: 			  #f18c14;
$blue-light:          #6db2e4;
$blue:                #5885c3;
$blue-dark:           #4866ab;

// Fonts stacks
$font-stack:              $roboto, Arial, Verdana, sans-serif;
$font-stack-alt:          Georgia, serif;
$font-size-base:          16px;
$line-height-base:        1.4;

// Typography
$color-text:              #333;
$color-link:              $orange;
$color-link-visited:      lighten($color-link, 20%);
$color-link-hover:        darken($color-link, 20%);
$color-link-active:       darken($color-link, 40%);
