.c-scroll-btn {
	color: black;
	text-transform: uppercase;
	font-size: 1rem;
	position: absolute;
	bottom: 80px;
	left: 60px;
	transform: rotate(90deg);

	@include mq('medium') {
		left: 20px;
	}


	&:before {
		content:"";
		display: block;
		height: 1px;
		width: 30px;
		background: #777;
		position: absolute;
		right: calc(100% + 10px);
		top: 50%;
		transform: translateX(0px);
		opacity: 0.6;
		transition: all 300ms ease;
	}

	&:hover {
		text-decoration: none;
	}

	&:hover:before {
		opacity: 1;
		transform: translateX(-10px);
	}
}